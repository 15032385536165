import React, { useState, useLayoutEffect } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import parse from "html-react-parser"

import {
  Section,
  SectionHeading,
  SectionLede,
} from "../styledComponents/section"

import {
  BookNowButton,
  PricingList,
  PricingListItem,
  PricingListItemLede,
  PricingListItemDetails,
  PricingListItemPrice 
} from "../styledComponents/pricing"
import { StyledCloseModalButton } from "../styledComponents/button"
import { ModalProvider, BaseModalBackground } from "styled-react-modal";
import { StyledModal } from "../styledComponents/modal"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SkusLevel1 from '../components/Products/SkusLevel1'
import SkusLevel1Discount20 from "../components/Products/SkusLevel1Discount20"
import SkusLevel1Discount30 from "../components/Products/SkusLevel1Discount30"
import SkusMasteryProgram from '../components/Products/SkusMastery'
import SkusCoachingProgram from '../components/Products/SkusCoachingProgram'
import CartOverview from '../components/Cart/CartOverview'
import KaarmiBeingLogo from "../components/kaarmiBeingLogo"
import Icon from "../components/icon"

function FancyModalButton(data) {

  const [isDiscountSet, setIsDiscountCookieSet] = useState(false)
  const [discountValue, getDiscountCookieValue] = useState(false)
  const [couponCodeInQuery, setCouponCodeInQuery] = useState(false)

  const checkDiscountCookieValue = (name) => {
    const re = new RegExp(name + "=([^;]+)")
    const value = re.exec(document.cookie) 
    const earlyBirdDiscountValue = unescape(value[1])    
    getDiscountCookieValue(earlyBirdDiscountValue)
  }

  const checkIfDiscountParam = () => {
    document.cookie
    .split(";")
    .some(item => item.trim().startsWith("eb="))
    ? checkDiscountCookieValue('eb')
    : setIsDiscountCookieSet(false)
  }

  const checkIfCouponCodeInQuery = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const couponCode = urlParams.get('cc')
    setCouponCodeInQuery(couponCode)
  }

  useLayoutEffect(() => {
    if (typeof window !== "undefined" || window.document) {
      checkIfDiscountParam()
      checkIfCouponCodeInQuery()
      return
    }
  }, [])


  const [isOpen, setIsOpen] = useState(false);
  const [opacity, setOpacity] = useState(0);

  function toggleModal(e) {
    setOpacity(0);
    setIsOpen(!isOpen);
  }

  function afterOpen() {
    setTimeout(() => {
      setOpacity(1);
    }, 100);
  }

  function beforeClose() {
    return new Promise((resolve) => {
      setOpacity(0);
      setTimeout(resolve, 300);
    });
  }
  
  let skuList
  if(data.type === 'Level 1'){
    // if(discountValue === 'f3Hj2ob221' || couponCodeInQuery === 'f3Hj2ob221'){
    //   skuList = <SkusLevel1Discount20 />
    // }
    // else if(discountValue === '0Ijss23Nmx1' || couponCodeInQuery === '0Ijss23Nmx1'){
    //   skuList = <SkusLevel1Discount30 />
    // }
    //else {
      skuList = <SkusLevel1 />
    //}
  }
  else if(data.type === 'Mastery'){
    skuList = <SkusMasteryProgram />
  }
  else if(data.type === 'Personal Consultation'){
    skuList = <SkusCoachingProgram />
  }

  return (
    <>
      <BookNowButton onClick={toggleModal}>Register</BookNowButton>
      <StyledModal
        isOpen={isOpen}
        afterOpen={afterOpen}
        beforeClose={beforeClose}
        onBackgroundClick={toggleModal}
        onEscapeKeydown={toggleModal}
        opacity={opacity}
        backgroundProps={{ opacity }}
        className="order-modal"
      >
        <StyledCloseModalButton onClick={toggleModal}>
          <Icon name="cross" />
        </StyledCloseModalButton>
        <h2><KaarmiBeingLogo color="blue"/></h2>
        {skuList}
        
        <CartOverview />
      </StyledModal>
    </>
  );
}

const FadingBackground = styled(BaseModalBackground)`
  opacity: ${(props) => props.opacity};
  transition: all 0.3s ease-in-out;
`;

const Heading = ({ level = 1, location, children }) => (
  <SectionHeading as={`h${level}`} level={level} location={location}>
    {children}
  </SectionHeading>
)

const Pricing = ({ data }) => {

  const [isDiscountSet, setIsDiscountCookieSet] = useState(false)
  const [discountValue, getDiscountCookieValue] = useState(false)

  const checkDiscountCookieValue = (name) => {
    setIsDiscountCookieSet(true)
    const re = new RegExp(name + "=([^;]+)")
    const value = re.exec(document.cookie) 
    const earlyBirdDiscountName = unescape(value[1])
    let earlyBirdDiscountValue
    if(earlyBirdDiscountName === 'f3Hj2ob221') {//20% off code
      earlyBirdDiscountValue = 20
    } else if(earlyBirdDiscountName === '0Ijss23Nmx1') {// 30% off code
      earlyBirdDiscountValue = 30
    }
    getDiscountCookieValue(earlyBirdDiscountValue)
  }

  const checkIfDiscountParam = () => {
    document.cookie
    .split(";")
    .some(item => item.trim().startsWith("eb="))
    ? checkDiscountCookieValue('eb')
    : setIsDiscountCookieSet(false)
  }
  useLayoutEffect(() => {
    if (typeof window !== "undefined" || window.document) {
      //checkIfDiscountParam()
      return
    }
  }, [])

  const lede = data.wpPage.content.replace(/<p[^>]*>/g, '').replace(/<\/p>/g, '')

  

  return (
    <>
    <Layout displayNavBorder>
      <SEO title={data.wpPage.title} />
      <Section>
        <Heading level={1}>
          {data.wpPage.title}
        </Heading>
        <SectionLede>
          {parse(lede)}
        </SectionLede>

        <PricingList>
          {data.wpPage.Pricing.kaarmibeingPriceList.map(
            (data, index) => {
              const afterDiscount = 339 - ( 339 * discountValue/100 );
            return (
              <>
              <PricingListItem>
                <h2>{data.kaarmibeingPriceHeader}</h2>
                <PricingListItemLede>{data.kaarmibeingPriceLede}</PricingListItemLede>
                <PricingListItemPrice>
                  {discountValue && data.kaarmibeingPrice === '339' ? (
                    <>
                      <span className="currency fullprice">&pound;</span><span className="value strikethrough">{data.kaarmibeingPrice}</span>
                      <span className="currency discountPrice">&pound;</span><span className="value discountPrice">{Math.round(afterDiscount)}</span>
                      <span className="discount-offer discountPrice">Save {discountValue}% </span>
                    </>
                  ) : <><span className="currency">&pound;</span><span className="value">{data.kaarmibeingPrice}</span></>}
                  {/* <div className="frequency">one off payment</div> */}
                </PricingListItemPrice>
                <PricingListItemDetails>
                  {parse(data.kaarmibeingProgramDetails)}
                </PricingListItemDetails>
                <ModalProvider backgroundComponent={FadingBackground}>
                  <FancyModalButton type={data.kaarmibeingPriceHeader}/>
                </ModalProvider>
              </PricingListItem>
              </>
            )}
          )}
        </PricingList>
      </Section>
    </Layout>
    </>
  )
}

export default Pricing

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    wpPage(slug: { eq: "book-now" }) {
      title
      content
      Pricing {
        kaarmibeingPriceList {
          kaarmibeingPrice
          kaarmibeingPriceHeader
          kaarmibeingPriceLede
          kaarmibeingProgramDetails
        }
      }
      SinglePrice {
        kaarmibeingPrice
        kaarmibeingPriceHeader
        kaarmibeingPriceLede
        kaarmibeingProgramDetails
      }
    }
  }
`
