import styled from "styled-components"
import { baseGridStyles } from "./base"
import breakpoints from "./breakpoints"
import { StyledButton } from "./button"

const PricingList = styled.ul`
    ${baseGridStyles}
    grid-column: span 4;
    list-style-type: none;
    padding: 0;
    margin: 0;
`

const PricingListItem = styled.li`
    grid-column: span 4;
    border: 1px solid var(--light-blue-lightest);
    border-radius: 3px;
    padding: 1.6rem;
    text-align: left;

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3){
        @media screen and (min-width: ${breakpoints.sm}px) {
            grid-column: 2 / 6;
        }
    }

    &:nth-child(1){
        @media screen and (min-width: ${breakpoints.md}px) {
            grid-column: 2 / 8;

            div {
                /* width: 80%; */
                /* margin: 0 auto; */
            }
        }
    }
    &:nth-child(2){
        @media screen and (min-width: ${breakpoints.md}px) {
            grid-column: 8 / 12;
        }
    }
    &:nth-child(3){
        @media screen and (min-width: ${breakpoints.md}px) {
            grid-column: 2 / 12;
            ul li {
                text-align: center;
                &::before {
                    display: none;
                }
            }
        }
    }

    @media screen and (min-width: ${breakpoints.sm}px) {
        grid-column: span 3;
    }

    @media screen and (min-width: ${breakpoints.md}px) {
        grid-column: span 4;
    }

    h2{
      text-align: center;  
    }


    button {
        display: block;
        /* margin: 0 auto; */
    }
`

const PricingListItemLede = styled.p`
    text-align: center;
    padding: 1.6rem 0;
`

const PricingListItemPrice = styled.p`
        padding-top: 1.6rem;
        padding-bottom: 1.6rem;
        text-align: center;
        border-top: 1px solid var(--light-blue-lightest);
        border-bottom: 1px solid var(--light-blue-lightest);
        margin: 1.6rem 2.8rem;
        .currency {
            margin-right: 0.375rem;
            color: #00ade0;
            font-size: 1.5rem;
            vertical-align: 56%;

            &.fullprice{
                color:var(--red-mid);
            }
            &.discountPrice {
                margin-left: 1rem;
                color: var(--green-strong);
            }
        }

        .value {
            color: #00ade0;
            font-weight: 700;
            font-size: 3.5rem;
            line-height: 4rem;
            text-align: center;

            &.discountPrice {
                color: var(--green-strong);
            }
        }
        .discount-offer {
            color: var(--green-strong);
            font-weight: 700;
            display: block;
        }
        .strikethrough {
            color: var(--red-mid);
            position: relative;
            &:before {
                position: absolute;
                content: "";
                left: 0;
                top: 50%;
                right: 0;
                border-top: 2px solid;
                border-color: inherit;
                transform:rotate(-20deg);
                
            }
        }
`

const PricingListItemDetails = styled.div`
    padding: 1.4rem 0 0;
    @media screen and (min-width: ${breakpoints.md}px) {
        /* width: 50%; */
    }
    ul{
        list-style-type:none;
        padding-left: 2.4rem;
        li {
            padding-left: 0;
            padding-right: 0;
            position:relative;
            padding-bottom: 1.4rem;

            &:last-of-type {
                padding-bottom: 0;
            }
        }

        li::before {
            content: ' ';
            position: absolute;
            top: 0.2rem;
            left: -2rem;
            display: inline-block;
            transform: rotate(38deg);
            height: 1.6rem;
            width: 1rem;
            border-bottom: 4px solid var(--green-strong);
            border-right: 4px solid var(--green-strong);
        }
    }
    
`
const BookNowButton = styled(StyledButton)`
    margin: 2.4rem auto 0;
    text-align: center;
    display: block;
`

export {
    BookNowButton,
    PricingList,
    PricingListItem,
    PricingListItemLede,
    PricingListItemPrice,
    PricingListItemDetails
}